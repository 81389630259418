<script lang="ts" setup>
enum InputVariants {
  'main',
  'ghost'
}

interface Props {
    placeholder?: string;
    type?: string;
    modelValue?: string;
    disabled?: boolean;
    variant?: keyof typeof InputVariants
}

const props = withDefaults(defineProps<Props>(), {
    variant: 'main'
})

const emits = defineEmits(["update:modelValue", "blur"]);

const innerValue = ref("");

const inputChange = (e: Event) => {
  innerValue.value = (e.target as HTMLInputElement).value;
  emits("update:modelValue", innerValue.value);
};

const onBlur = (e: Event) => {
  innerValue.value = (e.target as HTMLInputElement).value;
  emits("blur", innerValue.value);
};
</script>

<template>
  <div class="relative">
    <div class="relative flex items-center">
      <div v-if="$slots['icon-left']" class="icon-left">
        <slot name="icon-left" />
      </div>
      <input
        :class="['ui-input', `ui-input-${variant}`, { 'has-left-icon' : $slots['icon-left'] }, { 'has-right-icon' : $slots['icon-right'] }]"
        :type="type"
        :value="modelValue"
        :disabled="disabled"
        :placeholder="placeholder"
        spellcheck="false"
        @input="inputChange"
        @blur="onBlur"
      />
      <div v-if="$slots['icon-right']" class="icon-right">
        <slot name="icon-right" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.ui-input {
  @apply w-full px-4 border border-gray-300 bg-white text-gray-500 rounded-lg outline-none;
  &::placeholder {
    @apply text-gray-500;
  }
  &-main {
    @apply py-[11px] leading-tight text-sm font-normal bg-gray-50;
  }
  &-ghost {
    @apply text-base py-[13px];
  }
}
.has-left-icon {
    padding-left: 46px !important;
}
.has-right-icon {
    padding-right: 46px !important;
}
.icon-left,
.icon-right {
  @apply absolute top-0 bottom-0 flex;
}
.icon-left {
  @apply left-4;
}
.icon-right {
  @apply right-4;
}
</style>
